.dark-theme {
    background-color: #161c2d;
    color: white;
    transition: all 1s;
}

.dark-btn > button {
    background-color: #212529;
    color: white;
    transition: all 1s;
}

.content-toggle {
    background-color: white;
    color: black;
    transition: all 1s;
}

.btn-toggle > button {
    background-color: rgb(239, 239, 239);
    color: black;
    transition: all 1s;
}

.switch-dark-btn {
    background-color: #212529;
    color: white;
    transition: all 1s;
}

.switch-light-btn {
    background-color: rgb(239, 239, 239);
    color: black;
    transition: all 1s;
}

.under-message {
    margin-top: 150px;
}

.fixed-fix {
    position: fixed;
}

@media screen and (max-width: 992px) {
    .fixed-fix {
        position: static;
    }
    .under-message {
        margin-top: 100px;
    }
}