.left-arrow {
    animation: 2s 2s ease-in-out infinite alternate;
    animation-name: anim-left;
}

.right-arrow {
    animation: 2s 2s ease-in-out infinite alternate;
    animation-name: anim-right;  
}

.send-btn {
    background: linear-gradient(-90deg, rgb(63, 63, 112), rgb(43, 43, 78));
    color: white;
    border: none;
    transition: all 0.2s;
}

.send-btn:hover {
    transform: rotate(-5deg);
    transition: all 0.5s;
}

.grow-anim {
    animation: 2s infinite alternate;
    animation-name: anim-grow;
    transition: all 1s;
}

/* .ki:hover {
    transform: scale(1);
    animation: none;
    transition: all 1s;
} */

@keyframes anim-grow {
    0% {
        transform: scaleX(1);
        color: black;
    }
    100% {
        transform: scaleX(1.04);
        color: #115d11;
    }
}

@keyframes anim-left {
    0% {
        transform: translate(0, 0) rotate(45deg);
        color: #277e3e;
    }
    100% {
        transform: translate(-70px, 70px) rotate(45deg);
        color: black;
    }
}

@keyframes anim-right {
    0% {
        transform: translate(0, 0) rotate(-45deg);
        color: #277e3e;
    }
    100% {
        transform: translate(70px, 70px) rotate(-45deg);
        color: black;
    }
}