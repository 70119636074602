.span-img {
    transition: all 0.4s;
}

.span-img:hover {
    cursor: pointer;
    transform: scale(1.05);
    transition: all 0.7s;
}

.project-link a {
    font-size: 1.135em;
    color: rgb(4, 4, 177);
    text-decoration: none;
    /* transition: all 0.1s; */
}

@media only screen and (max-width: 668px) {
    .project-link a {
        font-size: 0.95em;
    }
}

.project-link div span {
    color: rgb(4, 4, 177);
}

.project-link a:hover {
    /* color: black; */
    text-decoration: underline;
    /* transition: all 0.3s; */
}

/* .project-hov {
    transition: all 0.3s;
}

.project-hov:hover {
    transform: translateY(-10px);
    transition: all 0.5s;
} */

.btn-details {
    color: black;
    background: white;
    border: 1px solid black;
    transition: all 0.2s;
}

.btn-details:hover {
    color: white;
    background: black;
    transition: all 0.4s;
}