.btn-resume {
  background: linear-gradient(-90deg, white, #6c757d);
  color: black;
  transition: all 0.15s;
}
.btn-resume:hover {
  background: linear-gradient(-90deg, #6c757d, #6c757d);
  color: white;
  transform: scale(1.1);
  transition: all 0.25s;
}
.profile-links a i,
.mail-link a {
  color: white;
  background: transparent;
  transform: scale(1);
  text-decoration: none;
}

.profile-links a:hover i,
.mail-link:hover a div i,
.mail-link:hover a div {
  color: #cad5e2;
  transform: scale(1.1);
  transition: all 0.4s;
}

.pro-pic {
  min-height: 497px;
}

.pro-pic img {
  border-radius: 100px;
}

.type::after {
  content: "_";
  animation: cursor 1.1s infinite step-start;
}

/* .mobile-horizontal {

  }

  .mobile-horizontal-remove {

  } */

@keyframes cursor {
  50% {
    opacity: 0;
  }
}

.pc-horizontal {
  background: #212529 url("https://i.ibb.co/Sy7wJsp/person1-1-1-1-1.png") center
    center fixed no-repeat;
  background-size: cover;
  background-blend-mode: color-burn;
  animation: 1.1s 2.6s infinite;
  animation-name: anims;
}

@media only screen and (max-width: 668px) {
  .mobile-horizontal {
    background: #212529 url("https://i.ibb.co/Sy7wJsp/person1-1-1-1-1.png")
      center bottom fixed no-repeat;
    background-blend-mode: color-burn;
    min-height: 600px;
    animation: 1.1s 2.6s infinite;
    animation-name: anims;
  }

  .mobile-horizontal-remove {
    display: none;
  }
}

@keyframes anims {
  0% {
    background-blend-mode: color-burn;
  }
  50% {
    background-blend-mode: multiply;
  }
  100% {
    background-blend-mode: color-burn;
  }
}
