.blog-hov {
    transition: all 0.2s;
}

.blog-hov:hover {
    transform: scale(1.1);
    transition: all 0.7s;
}

.blog-href {
    text-decoration: none;
}

.react-place {
    position: relative;
    top: 35px;
}

.react-style {
    background: white;
    border: 1px solid black;
}