.message::after {
    content: '•';
    font-weight: bold;
    color: green;
    animation: 0.6s ease-out infinite alternate;
    animation-name: blink;
}

@keyframes blink {
    0% {
        opacity: 0.1;
    }
    100% {
        opacity: 1;
    }
}