.switch-color {
    animation: 3.5s ease-in-out .2s infinite alternate-reverse;
    animation-name: switch;
}

@media only screen and (max-width: 668px) {
    .responsive-h4 h4 {
        font-size: 1em;
    }
}


.snow-up {
    animation: 3.2s ease-out infinite alternate;
    width: 0;
    border: 2px solid lightgreen;
    background-color: green;
    /* box-shadow: 1px 1px 5px lightgreen; */
    animation-name: snow;
}

.blog-prof-btn {
    background-color: rgb(20, 19, 19);
    color: aliceblue;
    border: none;
    padding: 12px 22px;
    transition: all 0.5s;
}

.blog-prof-btn:hover {
    background-color: rgb(58, 74, 128);
    transform: translate(50%, 0) scale(2, 1);
    transition: all 0.7s;
}

.rot-dot {
    display: inline-block;
    animation: 1s infinite;
    animation-name: rot-anim;
}

@keyframes rot-anim {
    0% {
        transform: rotateY(0deg);
    }
    50% {
        transform: rotateY(180deg);
    }
    100% {
        transform: rotateY(360deg);
    }
}

@media screen and (max-width: 600px) {
    .snow-up {
        animation-name: snow-mb;
    }
}

@keyframes snow {
    0% {
        width: 0;
    }

    100% {
        width: 100%;
    }
}

@keyframes snow-mb {
    0% {
        width: 0;
    }

    100% {
        width: 50%;
    }
}

@keyframes switch {
    0% {
        color: black;
    }

    100% {
        color: green;
    }
}